var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "id",
                      type: "number",
                      label: _vm.$t("Id"),
                      placeholder: _vm.$t("Id"),
                    },
                    model: {
                      value: _vm.filters.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "id", $$v)
                      },
                      expression: "filters.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "ean",
                      type: "number",
                      label: _vm.$t("Código de barras"),
                      placeholder: _vm.$t("Código de barras"),
                    },
                    model: {
                      value: _vm.filters.ean,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "ean", $$v)
                      },
                      expression: "filters.ean",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "name",
                      label: _vm.$t("Nome"),
                      placeholder: _vm.$t("Nome"),
                    },
                    model: {
                      value: _vm.filters.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "name", $$v)
                      },
                      expression: "filters.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "active",
                      type: "vue-select",
                      label: _vm.$t("Status"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.activeInactiveTypes(),
                    },
                    model: {
                      value: _vm.filters.active,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "active", $$v)
                      },
                      expression: "filters.active",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "hasImage",
                      type: "vue-select",
                      label: _vm.$t("Contém Imagem"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.yesNoTypes(),
                    },
                    model: {
                      value: _vm.filters.hasImage,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "hasImage", $$v)
                      },
                      expression: "filters.hasImage",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "brand",
                      type: "vue-select",
                      label: _vm.$t("Marca"),
                      placeholder: _vm.$t("Selecione uma marca"),
                      options: _vm.getComboBrands,
                    },
                    model: {
                      value: _vm.filters.brand,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "brand", $$v)
                      },
                      expression: "filters.brand",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "group",
                      type: "vue-select",
                      label: _vm.$t("Grupo"),
                      placeholder: _vm.$t("Selecione um grupo"),
                      options: _vm.getComboGroups,
                    },
                    on: { input: _vm.selectGroup },
                    model: {
                      value: _vm.filters.group,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "group", $$v)
                      },
                      expression: "filters.group",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "subgroup",
                      type: "vue-select",
                      label: _vm.$t("Subgrupo"),
                      placeholder: _vm.$t("Selecione um subgrupo"),
                      options: _vm.getComboSubgroups,
                      disabled: !_vm.filters.group,
                    },
                    model: {
                      value: _vm.filters.subgroup,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "subgroup", $$v)
                      },
                      expression: "filters.subgroup",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "department",
                      type: "vue-select",
                      label: _vm.$t("Departamento"),
                      placeholder: _vm.$t("Selecione um departamento"),
                      options: _vm.getComboDepartments,
                    },
                    model: {
                      value: _vm.filters.department,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "department", $$v)
                      },
                      expression: "filters.department",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "line",
                      type: "vue-select",
                      label: _vm.$t("Linha"),
                      placeholder: _vm.$t("Selecione uma linha"),
                      options: _vm.getComboLines,
                    },
                    model: {
                      value: _vm.filters.line,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "line", $$v)
                      },
                      expression: "filters.line",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "ncm",
                      type: "select-searchable",
                      label: _vm.$t("NCM"),
                      placeholder: _vm.$t("Digite para pesquisar"),
                      options: _vm.getComboNcms,
                      autofocus: "",
                    },
                    on: { fetchSearch: _vm.ncmSearch },
                    model: {
                      value: _vm.filters.ncm,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "ncm", $$v)
                      },
                      expression: "filters.ncm",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "active",
                      type: "vue-select",
                      label: _vm.$t("Em destaque"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.yesNoTypes(),
                    },
                    model: {
                      value: _vm.filters.featuredProduct,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "featuredProduct", $$v)
                      },
                      expression: "filters.featuredProduct",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "active",
                      type: "vue-select",
                      label: _vm.$t("Venda online"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.yesNoTypes(),
                    },
                    model: {
                      value: _vm.filters.activeOnline,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "activeOnline", $$v)
                      },
                      expression: "filters.activeOnline",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "active",
                      type: "vue-select",
                      label: _vm.$t("Venda B2B"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.yesNoTypes(),
                    },
                    model: {
                      value: _vm.filters.activeB2B,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "activeB2B", $$v)
                      },
                      expression: "filters.activeB2B",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "products-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.$t("Nenhum produto cadastrado"),
              fields: _vm.fields,
              items: _vm.products,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c(
                      "span",
                      [
                        _c("e-grid-actions", {
                          attrs: {
                            "show-update": _vm.$can("Update", "Product"),
                            "show-delete": _vm.$can("Delete", "Product"),
                            buttons: [
                              {
                                icon: "search",
                                variant: "primary",
                                title: _vm.$t("Visualizar produto"),
                                event: "product-read-only",
                              },
                            ],
                          },
                          on: {
                            update: function ($event) {
                              return _vm.showUpdateProductPage(row)
                            },
                            delete: function ($event) {
                              return _vm.deleteProduct(row)
                            },
                            "product-read-only": function ($event) {
                              return _vm.showReadOnlyProductPage(row)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(active)",
                fn: function (ref) {
                  var value = ref.value
                  return [_c("e-status-badge", { attrs: { status: value } })]
                },
              },
              {
                key: "cell(tax)",
                fn: function (ref) {
                  var value = ref.value
                  return [
                    _c("b", [_vm._v(_vm._s(_vm.$t("Ncm")) + ":")]),
                    value.ncm
                      ? _c(
                          "span",
                          { attrs: { title: value.ncm.description } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(value.ncmCode || "-") +
                                " - " +
                                _vm._s(
                                  _vm._f("truncate")(
                                    value.ncm.description || "-",
                                    20
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _c("span", [_vm._v("-")]),
                    _c("br"),
                    _c("b", [_vm._v(_vm._s(_vm.$t("Cest")) + ":")]),
                    value.cest
                      ? _c(
                          "span",
                          { attrs: { title: value.cest.description } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(value.cest.code || "-") +
                                " - " +
                                _vm._s(
                                  _vm._f("truncate")(
                                    value.cest.description || "-",
                                    20
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
              {
                key: "cell(hasImage)",
                fn: function (ref) {
                  var value = ref.value
                  return [
                    _c("e-status-badge", {
                      attrs: { status: value, type: "yesNo" },
                    }),
                  ]
                },
              },
              {
                key: "cell(activeOnline)",
                fn: function (ref) {
                  var value = ref.value
                  return [
                    _c("e-status-badge", {
                      attrs: { status: value, type: "yesNo" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "product-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$can("Create", "Product")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar Produto") },
            on: { click: _vm.showCreatePage },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }