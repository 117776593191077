<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <b-row>
        <b-col md="2">
          <FormulateInput
            id="id"
            v-model="filters.id"
            type="number"
            :label="$t('Id')"
            :placeholder="$t('Id')"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="ean"
            v-model="filters.ean"
            type="number"
            :label="$t('Código de barras')"
            :placeholder="$t('Código de barras')"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            id="name"
            v-model="filters.name"
            :label="$t('Nome')"
            :placeholder="$t('Nome')"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="active"
            v-model="filters.active"
            type="vue-select"
            :label="$t('Status')"
            :placeholder="$t('Todos')"
            :options="activeInactiveTypes()"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="hasImage"
            v-model="filters.hasImage"
            type="vue-select"
            :label="$t('Contém Imagem')"
            :placeholder="$t('Todos')"
            :options="yesNoTypes()"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="brand"
            v-model="filters.brand"
            type="vue-select"
            :label="$t('Marca')"
            :placeholder="$t('Selecione uma marca')"
            :options="getComboBrands"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="group"
            v-model="filters.group"
            type="vue-select"
            :label="$t('Grupo')"
            :placeholder="$t('Selecione um grupo')"
            :options="getComboGroups"
            @input="selectGroup"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="subgroup"
            v-model="filters.subgroup"
            type="vue-select"
            :label="$t('Subgrupo')"
            :placeholder="$t('Selecione um subgrupo')"
            :options="getComboSubgroups"
            :disabled="!filters.group"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="department"
            v-model="filters.department"
            type="vue-select"
            :label="$t('Departamento')"
            :placeholder="$t('Selecione um departamento')"
            :options="getComboDepartments"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="line"
            v-model="filters.line"
            type="vue-select"
            :label="$t('Linha')"
            :placeholder="$t('Selecione uma linha')"
            :options="getComboLines"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            id="ncm"
            v-model="filters.ncm"
            type="select-searchable"
            :label="$t('NCM')"
            :placeholder="$t('Digite para pesquisar')"
            :options="getComboNcms"
            autofocus
            @fetchSearch="ncmSearch"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="active"
            v-model="filters.featuredProduct"
            type="vue-select"
            :label="$t('Em destaque')"
            :placeholder="$t('Todos')"
            :options="yesNoTypes()"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="active"
            v-model="filters.activeOnline"
            type="vue-select"
            :label="$t('Venda online')"
            :placeholder="$t('Todos')"
            :options="yesNoTypes()"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="active"
            v-model="filters.activeB2B"
            type="vue-select"
            :label="$t('Venda B2B')"
            :placeholder="$t('Todos')"
            :options="yesNoTypes()"
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="products-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="$t('Nenhum produto cadastrado')"
        :fields="fields"
        :items="products"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(action)="row">
          <span>
            <e-grid-actions
              :show-update="$can('Update', 'Product')"
              :show-delete="$can('Delete', 'Product')"
              :buttons="[
                {
                  icon: 'search',
                  variant: 'primary',
                  title: $t('Visualizar produto'),
                  event: 'product-read-only',
                },
              ]"
              @update="showUpdateProductPage(row)"
              @delete="deleteProduct(row)"
              @product-read-only="showReadOnlyProductPage(row)"
            />
          </span>
        </template>
        <template #cell(active)="{ value }">
          <e-status-badge :status="value" />
        </template>

        <template #cell(tax)="{ value }">
          <b>{{ $t('Ncm') }}:</b>
          <span
            v-if="value.ncm"
            :title="value.ncm.description"
          >
            {{ value.ncmCode || '-' }} - {{ value.ncm.description || '-' | truncate(20) }}
          </span>
          <span v-else>-</span>
          <br>
          <b>{{ $t('Cest') }}:</b>
          <span
            v-if="value.cest"
            :title="value.cest.description"
          >
            {{ value.cest.code || '-' }} - {{ value.cest.description || '-' | truncate(20) }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(hasImage)="{ value }">
          <e-status-badge
            :status="value"
            type="yesNo"
          />
        </template>
        <template #cell(activeOnline)="{ value }">
          <e-status-badge
            :status="value"
            type="yesNo"
          />
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="product-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      v-if="$can('Create', 'Product')"
      :main-tooltip="$t('Adicionar Produto')"
      @click="showCreatePage"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary, EFilters, EGridActions } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { statusTypes } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import EStatusBadge from '@/views/components/EStatusBadge.vue'

export default {
  name: 'ProductList',
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EFilters,
    EGridActions,
    EStatusBadge,
  },

  mixins: [statusTypes],

  data() {
    return {
      fetching: false,
      edit: false,
      saving: false,
      product: {
        id: null,
        name: '',
      },
    }
  },

  computed: {
    ...mapState('pages/catalog/products', [
      'filters',
      'sorting',
      'products',
      'paging',
      'productCombos',
    ]),
    ...mapGetters('pages/catalog/products', [
      'getComboDepartments',
      'getComboLines',
      'getComboUnits',
      'getComboBrands',
      'getComboGroups',
      'getComboSubgroups',
    ]),
    ...mapGetters('common/ncms', ['getComboNcms']),
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          tdClass: 'text-right',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('EAN'),
          key: 'ean',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          thClass: 'text-center',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Fiscal'),
          key: 'tax',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '80px' },
        },
        {
          label: this.$t('Imagem'),
          key: 'hasImage',
          class: 'text-center',
          thStyle: { width: '80px' },
        },
        {
          label: this.$t('Site'),
          key: 'activeOnline',
          class: 'text-center',
          thStyle: { width: '80px' },
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/catalog/products', [
      'fetchProducts',
      'fetchProductCombos',
      'setFilter',
      'setCurrentPage',
      'setPageSize',
      'setComboSubgroups',
      'resetFilters',
    ]),
    ...mapActions('common/ncms', ['fetchNcms']),

    async ncmSearch(loading, search) {
      try {
        await this.fetchNcms({ itemSearch: search })
      } catch (e) {
        this.showError({
          message: this.$t('Não foi possível fazer a pesquisa'),
        })
      }
    },

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchProducts()
          await this.fetchProductCombos()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    selectGroup(idValue) {
      this.filters.subgroup = null
      this.setComboSubgroups(idValue)
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    showCreatePage() {
      this.product = { name: '' }
      this.$router.push({ name: 'product-add' })
    },

    async showUpdateProductPage(row) {
      const { id } = row.item
      this.$router.push({ name: 'product-maintain', params: { id } })
    },

    async showReadOnlyProductPage(row) {
      const { id } = row.item
      this.$router.push({ name: 'product-view', params: { id } })
    },

    async deleteProduct(product) {
      const { id } = product.item
      try {
        const confirm = await this.confirm()
        if (confirm) {
          this.fetching = true
          await this.$http.delete(`/api/products/${id}`)

          await this.getData()
          this.showSuccess({
            message: this.$t('Produto removido com sucesso'),
          })
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
  },
}
</script>

<style></style>
